<template>
    <div style="height: 100%;">
        <v-system-bar
            color="secondary"
            dark
            height="30"
        >
            <span>{{ $auth.user.name }}</span>
            <v-spacer></v-spacer>
            <span>{{ $t("document.reviewing", {document: document_name}) }}</span>
            <v-spacer></v-spacer>
            <span>{{ $store.state.company.name }}</span>
        </v-system-bar>
        <iframe :src="url"></iframe>
        <v-overlay
            :absolute="true"
            :opacity="0.9"
            :value="loading"
        >
            <v-progress-circular
                color="purple"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";

export default {
    name: "Review",
    data() {
        return {
            url: "",
            loading: true,
            document_name: null
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.documents"),
                to: "/documents",
                exact: true
            },
            {
                text: this.$t("document.view"),
                to: "/documents/" + this.$route.params.uuid,
                exact: true
            },
            {
                text: this.$t("document.review"),
                to: "/documents/" + this.$route.params.uuid + "/review",
                exact: true
            }
        ]);

        Accounting.get("/documents/" + this.$route.params.uuid)
            .then(resp => {
                this.document_name = resp.data.name;
            })
            .catch(() => {
            });

        let inst = this;

        this.loading = true;
        Accounting.get("/documents/" + this.$route.params.uuid + "/review")
            .then(response => {
                const data = response.data;
                this.url = data.url;

                function receiveMessage(event) {
                    // eslint-disable-next-line no-unused-vars
                    const actions = {
                        "status_callback_cancel": {
                            "type": "warning",
                            "message": inst.$t("document.review_cancelled")
                        },
                        "status_callback_return": {
                            "type": "success",
                            "message": inst.$t("document.review_completed")
                        },
                        "status_callback_postpone": {
                            "type": "info",
                            "message": inst.$t("document.review_postponed")
                        }
                    };

                    if (actions[event.data] !== undefined) {
                        inst.$store.commit("SET_ALERTS", [{
                            type: actions[event.data]["type"],
                            text: actions[event.data]["message"]
                        }]);
                        window.removeEventListener("message", receiveMessage, false);
                        inst.continueStreak(event.data);
                    }
                }

                window.addEventListener("message", receiveMessage, false);

                this.loading = false;
            }).catch(error => {
            if (error.response) {
                let errors = [];
                for (const errorElement of error.response.data.errors) {
                    errors.push({
                        type: "error",
                        text: errorElement
                    });
                }
                this.$store.commit("SET_ALERTS", errors);
            } else if (error.request) {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: this.$t("api.no_response")
                }]);
            } else {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: error.message
                }]);
            }
            this.$router.push("/documents/" + this.$route.params.uuid);
            this.loading = false;
        });
    },
    methods: {
        continueStreak(event) {
            if (event === "status_callback_cancel") {
                this.$ls.set("review_streak", null);
                this.$router.push("/documents/" + this.$route.params.uuid);
                return;
            }

            if (this.$ls.get("review_streak") === null) {
                this.$router.push("/documents/" + this.$route.params.uuid);
                return;
            }

            let docs = this.$ls.get("review_streak");
            docs.push(this.$route.params.uuid);
            this.$ls.set("review_streak", docs);

            this.loading = true;
            Accounting.get("/external/rossum/reviews?per_page=-1")
                .then((response) => {
                    for (const document of response.data["documents"]) {
                        if (!this.$ls.get("review_streak").includes(document["uuid"])) {
                            console.log("nex doc", document);
                            window.location = "/documents/" + document["uuid"] + "/review";
                            break;
                        }
                    }
                    this.$ls.set("review_streak", null);
                    this.$router.push("/documents/" + this.$route.params.uuid);
                });
        }
    }
};
</script>

<style scoped>
iframe {
    width: 100%;
    height: calc(100% - 30px);
    border: 0;
}
</style>
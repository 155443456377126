import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=fd5677a0&scoped=true&"
import script from "./Review.vue?vue&type=script&lang=js&"
export * from "./Review.vue?vue&type=script&lang=js&"
import style0 from "./Review.vue?vue&type=style&index=0&id=fd5677a0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd5677a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VOverlay,VProgressCircular,VSpacer,VSystemBar})
